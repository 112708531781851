import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchUserProfile();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
      setUser(response.data);
    } catch (error) {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
    } finally {
      setLoading(false);
    }
  };

  const login = async (auth_code) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      auth_code
    });
    const { token } = response.data;
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await fetchUserProfile();
    return response.data;
  };

  const tg_auth = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/tg_auth`, data);
    const { token } = response.data;
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await fetchUserProfile();
    return response.data;
  };


  const logout = async () => {
    try {
      // Отправляем запрос на сервер для выхода из системы
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`);
      
      if (response.data.message === 'Logged out successfully') {
        // Очищаем токен из локального хранилища
        localStorage.removeItem('token');
        // Удаляем токен из заголовков axios
        delete axios.defaults.headers.common['Authorization'];
        // Сбрасываем данные пользователя
        setUser(null);
      }
    } catch (error) {
      console.error('Ошибка при выходе из системы:', error);
      // Даже если произошла ошибка на сервере, очищаем локальные данные
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      setUser(null);
      throw new Error('Не удалось выполнить выход из системы');
    }
  };

  const value = {
    user,
    loading,
    login,
    tg_auth,
    logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
