import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import { AuthProvider, useAuth } from './context/AuthContext';
import Navbar from './components/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Slots from './pages/Slots';
import SlotGame from './pages/SlotGame';

// Theme configuration1
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFD700', // Gold color
    },
    secondary: {
      main: '#C0C0C0', // Silver color
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
  },
});

  const App = () => {
    const [isTelegram, setIsTelegram] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // состояние загрузки
  
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('miniApp') === 'true') {
        console.log('Приложение открыто в Telegram Mini App');
        setIsTelegram(true);
        
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js?58";
        script.async = true;
        
        script.onload = async () => {
          if (window.Telegram && window.Telegram.WebApp) {
            try {
              const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/tg_auth`, { validate: window.Telegram.WebApp.initData });
              console.log(['DATA BACK', response.data]);
              const { token, user } = response.data;
              localStorage.setItem('token', token);
              localStorage.setItem('user', JSON.stringify(user));
              axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            } catch (error) {
              console.error('Error:', error);
              window.Telegram.WebApp.close();
            }
          } else {
            console.log('Объект Telegram.WebApp недоступен');
          }
          setIsLoading(false); // Завершаем загрузку
        };
        
        document.body.appendChild(script);
        console.log('Скрипт Telegram подключен');
      } else {
        console.log('Приложение открыто в браузере');
        setIsLoading(false); // Если не в Telegram, тоже завершаем загрузку
      }
    }, []);
  
    if (isLoading) {
      return <div>Загрузка...</div>; // Показываем экран загрузки, пока не завершится инициализация
    }


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>

            <Route path="/" element={<>
              <Navbar />
              <Home />
            </>} />
            <Route path="/login" element={<>
              <Navbar />
              <Login />
            </>} />


            {/* Protected routes */}
            <Route path="/me" element={
              <ProtectedRoute>
                <Navbar />
                <Profile />
              </ProtectedRoute>
            } />
            <Route path="/slots" element={
              <ProtectedRoute>
                <Navbar />
                <Slots />
              </ProtectedRoute>
            } />
            <Route path="/slots/:id" element={
              <ProtectedRoute>
                <Navbar />
                <SlotGame />
              </ProtectedRoute>
            } />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
