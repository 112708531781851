import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Chip,
  IconButton,
  Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../context/AuthContext';
import { slotsAPI } from '../utils/api';
import { getErrorMessage } from '../utils/errorHandler';
import LoadingSpinner from '../components/LoadingSpinner';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from 'react-router-dom';

// Стилизованные компоненты
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
    '& .play-icon': {
      opacity: 1,
    },
    '& .media-overlay': {
      opacity: 1,
    }
  },
}));

const StyledCardMedia = styled(CardMedia)({
  paddingTop: '75%', // 4:3 соотношение сторон
  backgroundSize: 'contain',
  backgroundColor: '#1a1a1a',
  position: 'relative',
});

const MediaOverlay = styled(Box)({
  position: 'absolute',
  top: 0, 
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Полупрозрачный черный
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
});

const StyledChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontWeight: 'bold',
}));

const PlayIconWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  '& .play-icon': {
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    color: 'white',
    fontSize: '4rem',
    filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5))',
  },
}));

const Slots = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const handleSlotClick = (slotId) => {
    navigate(`/slots/${slotId}`);
  };

  const fetchSlots = useCallback(async () => {
    try {
      const response = await slotsAPI.getSlots();
      setSlots(response.data);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSlots();
  }, [fetchSlots]);

  if (loading) {
    return <LoadingSpinner message="Loading slots..." />;
  }

  if (error) {
    return (
      <Container>
        <Box mt={4}>
          <Typography color="error" variant="h6" align="center">
            {error}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
          Available Slots
        </Typography>
        
        <Grid container spacing={3}>
          {slots.map((slot) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={slot.id}>
              <StyledCard>
                <CardActionArea onClick={() => handleSlotClick(slot.id)}>
                  <Box sx={{ position: 'relative' }}>
                    <StyledCardMedia
                      image={slot.icon}
                      title={slot.name}
                    />
                    <MediaOverlay className="media-overlay" />
                    <StyledChip
                      label={`RTP ${slot.rtp}%`}
                      size="small"
                    />
                    <PlayIconWrapper>
                      <PlayCircleOutlineIcon className="play-icon" />
                    </PlayIconWrapper>
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2" noWrap>
                      {slot.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Slots;