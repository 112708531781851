import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Создаем инстанс axios с базовым URL
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Добавляем интерцептор для добавления токена к запросам
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API для работы со слотами
export const slotsAPI = {
  // Получить список всех слотов
  getSlots: () => api.get('/slots'),

  // Получить информацию о конкретном слоте
  getSlot: (slotId) => api.get(`/slots/${slotId}`),

  // Сделать ставку и сыграть в слот
  playSlot: (slotId, betAmount) => api.post(`/slots/${slotId}/play`, { betAmount }),

  // Получить историю игр пользователя
  getHistory: () => api.get('/slots/history')
};

// API для работы с пользователем
export const userAPI = {
  // Получить профиль пользователя
  getProfile: () => api.get('/users/me'),

  // Обновить профиль пользователя
  updateProfile: (data) => api.put('/users/me', data),

  // Пополнить баланс
  deposit: (amount) => api.post('/users/deposit', { amount }),

  // Вывести средства
  withdraw: (amount) => api.post('/users/withdraw', { amount })
};

// API для аутентификации
export const authAPI = {

  // Вход
  login: (data) => axios.post(`${API_URL}/auth/login`, data),
  tg_auth: (data) => axios.post(`${API_URL}/auth/tg_auth`, data),
  // Выход
  logout: () => api.post('/auth/logout'),

  // Обновление токена
  refreshToken: () => api.post('/auth/refresh')
};

export default api;
