import React from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';

const GameContainer = styled(Box)({
  width: '100%',
  height: 'calc(100vh - 64px)', 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1a1a1a',
  padding: '20px'
});

const GameFrame = styled('iframe')({
  width: '1024px', 
  height: '768px', 
  border: 'none',
  backgroundColor: '#000',
  borderRadius: '8px',
  boxShadow: '0 0 20px rgba(0,0,0,0.5)'
});

const SlotGame = () => {
  const { id } = useParams();
  const {user} = useAuth();


  return (
    <GameContainer>
      <GameFrame
        src={`https://apis.miniap.ru/slotFolder/?id=${id}&session_id=${user.session_id}&v=10`}
        title="Slot Game"
        allow="fullscreen"
      />
    </GameContainer>
  );
};

export default SlotGame;